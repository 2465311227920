import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: '/',
    component: () => import('@/views'),
    meta: {
      title: '主页'
    },
    redirect: '/shMap',
    children: [
      {
        path: '/shMap',
        name: 'shMap',
        component: () => import('@/views/shMap.vue'),
        meta: {
          auth: {
            required: false,
          },
        }
      },
    ]
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

